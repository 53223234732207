import { firebaseGetUsername } from '@/firebase/utils'
import { useEffect, useState } from 'react'
import { BsvIcon } from '@/icons/index'

export const calculateTimeMultiplier = (createdAt, expiryDate) => {
  const createdDateVal = createdAt.toDate()
  const expiryDateVal = new Date(expiryDate)
  const hoursDifference =
    (expiryDateVal.getTime() - createdDateVal.getTime()) / (60 * 60 * 1000)
  return parseFloat(`${1 + 0.005 * hoursDifference}`).toFixed(4)
}

export const getExpireyDate = (data) => {
  const date = new Date(data)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  return 'Expires ' + formattedDate
}

export const getAmountRenderer = (bsvToUsd) => {
  const AmountRenderer = ({ data }) => {
    if (data?.amount) {
      const { amount } = data
      const dollar = bsvToUsd(amount)

      return (
        <div className="text-sm font-medium">
          <div className="flex gap-x-2 items-center relative">
            <BsvIcon className="w-4 h-4" />
            {parseFloat(amount).toFixed(4)}
          </div>
          <div>$ {parseFloat(dollar).toFixed(4)}</div>
        </div>
      )
    }
  }
  // Setting displayName for debugging purposes
  AmountRenderer.displayName = 'AmountRenderer'

  return AmountRenderer
}

export const UserRenderer = (props) => {
  const data = props.data
  const [username, setUsername] = useState('')
  useEffect(() => {
    if (data) {
      firebaseGetUsername(data.user).then(setUsername)
    }
  }, [data])

  if (data) {
    return <span>{username}</span>
  }
}

export const getRowId = (params) => params?.data?.id

export const dateFormatter = (params) => params?.value?.toDate().toDateString()
